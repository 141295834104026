import React from 'react'
import Layout from 'layouts/fr'
import Template from 'templates/faq'

export default ({ justContent }) => {
  if (justContent) {
    return (
      <Template lang="fr" list={list} />
    )
  }

  return (
    <Layout p={5}>
      <Template lang="fr" list={list} />
    </Layout>
  )
}

const list = (
  <section className="template">
    <h1 className="headline">FAQ</h1>
    <article className="faq show">
      <div className="question">
        <p className="text bold">Qu’est-ce que Stairlin?</p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Stairlin est à la fois une entreprise et une idée. Notre entreprise
          est basée à Lausanne avec un bureau opérationnel à Zurich. Notre but
          est de construire la première vraie plateforme en ligne pour l’accès
          aux services des micro entreprises en Suisse et en Europe. En
          particulier dans le domaine de la santé, du sport et de l’éducation.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">Que faites-vous?</p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Nous rendons la réservation, la facturation et la gestion de clientèle
          pour les entreprises et nous rendons la réservation et le paiement
          quasi instantané pour leurs clients. Pourquoi? Car pour les petits
          prestataires de service et les indépendants, nous avons constaté que
          ces tâches sont encore souvent réalisées de façon manuelle. Et pour
          les clients et patients il est toujours pénible de rechercher,
          réserver et penser au paiement pour des services.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">Comment puis-je essayer Stairlin?</p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Tout simplement en vous enregistrant sur notre page internet. Une fois
          connecté vous pouvez créer un nouveau business et le paramétrer. Après
          quelques minutes, il vous est déjà possible d'offrir la réservation
          instantanée à vos clients ou vos patients depuis tous les navigateurs
          internet et sur plusieurs plateformes existantes, y compris sur
          Google, Facebook, Instagram, votre propre site web, etc.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          Comment puis-je paramétrer mon business sur Stairlin de façon à ce que
          mes clients puissent réserver mes services?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Pour paramétrer les heures auxquelles les clients peuvent réserver vos
          services: cliquez sur le logo Stairlin, sélectionnez paramètres,
          calendrier et finalement disponibilités. Pour paramétrer les services
          que vous offrez: cliquez sur le logo Stairlin, sélectionnez paramètres
          puis services.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          Ais-je besoin d’installer ou de télécharger quelque chose pour que
          Stairlin fonctionne?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Vous n’avez pas besoin d’installer quoi que ce soit pour que Stairlin
          fonctionne. Tout ce qui est nécessaire est l’ordinateur, la tablette
          ou le téléphone que vous utilisez au quotidien ainsi qu’une connexion
          à internet.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          Comment mes clients ou mes patients peuvent-ils réserver mes services?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Vos clients et patients peuvent réserver où et quand ils le désirent.
          Vous pouvez rajouter le lien vers votre page business Stairlin où vous
          le souhaitez : que ce soit depuis Facebook, Instagram ou votre site
          web. Vous pouvez facilement copier le lien vers votre page business
          depuis cette dernière en appuyant sur la petite icône de partage.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          J’utilise des réseaux sociaux ou mon site web pour interagir avec mes
          clients ou mes patients. Est-ce que je dois changer mes habitudes pour
          qu’ils puissent réserver en ligne?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Absolument pas ! Comme expliqué plus haut, vous pouvez utiliser
          n’importe quel canal de communication pour faire en sorte que vos
          clients puissent réserver et payer pour vos services en un seul clic.
          <br />
          Vous avez un compte Facebook pour votre entreprise? Pas de problème,
          ajoutez un bouton “réserver maintenant” sur votre page Facebook au
          lieu de “contactez-nous” pour la rendre encore plus efficace.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          Je n’ai jamais eu à entrer un mot de passe, est-ce normal?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Oui, c’est fait exprès! Il est difficile de se rappeler les mots de
          passe et nous avons donc tendance à les réutiliser. Des brèches dans
          les bases de données ont lieu tous les jours et une seule brèche peut
          potentiellement révéler votre mot de passe à des personnes de munies
          de mauvaises intentions. C’est pour quoi nous avons décidé d’approcher
          la façon dont nous identifions les utilisateurs sous un autre angle.
          <br />
          Il existe trois différentes façons d’identifier un utilisateur. La
          première est par la connaissance (quelque chose que seuls vous
          “savez”), qui comprend les mots de passe, code PIN, etc. La deuxième
          est la possession (quelque chose que vous “avez”), ceci comprend les
          numéro de téléphone, emails, etc. La dernière est quelque chose qui
          vous est intrinsèque (quelque chose que vous “êtes”), cela comprend
          les empreintes digitales, la reconnaissance faciale, etc.
          <br />
          Notre système d’authentification utilise les facteurs de possession
          comme première couche de sécurité. Chaque fois que vous tentez de vous
          connecter à votre compte depuis un appareil inconnu, Stairlin vous
          fait parvenir un code à 6 chiffres sur votre téléphone portable, de
          cette manière seule la personne en possession du téléphone peut
          accéder au compte.
          <br />
          Comme la sécurité de votre compte nous importe plus que tout, nous
          avons déjà prévu de rajouter d’autres méthodes d’identification
          complémentaires dans un futur proche.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          La communication et sauvegarde de mes données sont-elles sécurisées?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Toute communication entre votre appareil et Stairlin à lieu via un
          canal sécurisé et encrypté. Nous utilisons actuellement le standard
          TLS 1.2 avec une clé RSA à 2048 bits. Tous nos serveurs communiquent
          via un réseau virtuel complètement isolé d’autres serveurs.
          <br />
          Toutes les données sensibles sont cryptées en utilisant des méthodes
          conformes au standard PCI DSS et ISO 27001 et sont stockées dans des
          centres de données gouvernés par les lois les plus strictes en matière
          de protection des données.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          Comment puis-je désactiver/détruire mon compte?
        </p>
        <img className="arrow" src="/img/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Si vous possédez un business sur Stairlin, passez sur votre compte
          professionnel, allez dans les paramètres puis compte et enfin
          “Détruire le compte”. Une fois votre compte professionnel détruit,
          vous pourrez détruire votre compte personnel depuis la page paramètre
          utilisateur.
        </p>
      </div>
    </article>
  </section>
);
