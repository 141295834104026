import { Div, Text, Title } from 'components'
import Layout from 'layouts/fr'
import Faq from 'pages/faq.fr'
import React from 'react'

export default (props) => {
  console.log('page props', props)
  return (
    <Layout p={5}>
      <Div pt={8} textAlign={'center'}>
        <Title mb={[5,6]} fontSize={[7,9]}>Nous sommes ici pour aider</Title>
        <Text mb={[6,8]} fontSize={[6,8]}>Notre temps de réponse est généralement de 1 heure</Text>
      </Div>

      <Div mx={[-36,0]}>
        <Faq justContent/>
      </Div>
    </Layout>
  )
}
